import Vue from 'vue';

export function listGamePages(params) {
  return Vue.$http({
    url: '/gamePages/listGamePages',
    method: 'get',
    params,
  });
}

export function getGamePage(params) {
  return Vue.$http({
    url: '/gamePages/getGamePage',
    method: 'get',
    params,
  });
}

export function updateGamePage(data) {
  return Vue.$http({
    url: '/gamePages/updateGamePage',
    method: 'post',
    data,
  });
}

export function createGamePage(data) {
  return Vue.$http({
    url: '/gamePages/createGamePage',
    method: 'post',
    data,
  });
}

export function deleteGamePage(data) {
  return Vue.$http({
    url: '/gamePages/deleteGamePage',
    method: 'post',
    data,
  });
}

export function getAttributeTypes(params) {
  return Vue.$http({
    url: '/gamePages/getAttributeTypes',
    method: 'get',
    params,
  });
}

export function generateRelativeUrl(params) {
  return Vue.$http({
    url: '/gamePages/generateRelativeUrl',
    method: 'get',
    params,
  });
}

export function getImage(params) {
  return Vue.$http({
    url: '/gamePages/getImage',
    method: 'get',
    params,
  });
}

export function updateImage(data) {
  return Vue.$http({
    url: '/gamePages/updateImage',
    method: 'post',
    data,
  });
}

export function deleteImage(data) {
  return Vue.$http({
    url: '/gamePages/deleteImage',
    method: 'post',
    data,
  });
}

export function listImages(params) {
  return Vue.$http({
    url: '/gamePages/listImages',
    method: 'get',
    params,
  });
}
