<template>
  <div class="flex-form">
    <el-form
      ref="imageForm"
      v-loading="loading"
      :model="imageForm"
      label-width="170px"
      autocomplete="off"
    >
      <el-form-item>
        <h2>
          Edit Image
        </h2>
      </el-form-item>

      <el-form-item
        v-if="isEditMode"
        label="Uid"
      >
        {{ mediaUid }}
      </el-form-item>

      <el-form-item
        label="Active"
        prop="active"
      >
        <el-switch
          v-model="imageForm.isActive"
        />
      </el-form-item>

      <el-form-item
        label="Image"
      >
        <upload-media
          ref="gamePageImage"
          :image-url="imageUrl"
          :params="{ contentType: 'GAME_PAGE_IMAGE' }"
          :deletable="false"
          @success="handleImageUploadSuccess"
        />
      </el-form-item>

      <el-form-item
        label="Tags"
      >
        <el-tag
          v-for="tag in imageForm.tags"
          :key="tag"
          closable
          :disable-transitions="false"
          @close="handleTagClose(tag)"
        >
          {{ tag }}
        </el-tag>
        <el-input
          v-if="tagInputVisible"
          ref="saveTagInput"
          v-model="tagInputValue"
          size="mini"
          class="input-new-tag"
          @keyup.enter.native="handleTagInputConfirm"
          @blur="handleTagInputConfirm"
        />
        <el-button
          v-else
          class="button-new-tag"
          size="small"
          @click="showInput"
        >
          + New Tag
        </el-button>
      </el-form-item>

      <el-form-item class="flex-form__action-buttons">
        <el-button
          type="primary"
          :loading="saving"
          :disabled="!imageForm.mediaUid"
          @click="onSubmit"
        >
          Save
        </el-button>

        <el-button @click="onCancel">
          Cancel
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import UploadMedia from '@/components/UploadMedia';
import {
  getImage,
  updateImage,
} from '@/api/gamePage';

export default {
  name: 'ImageDetails',
  components: {
    UploadMedia,
  },
  props: {
    isEditMode: {
      default: false,
      type: Boolean,
    },
    mediaUid: {
      default: null,
      type: String,
    },
    visible: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      saving: false,
      imageForm: {
        mediaUid: null,
        isActive: true,
      },
      image: {},
      tagInputVisible: false,
      tagInputValue: '',
    };
  },
  computed: {
    imageUrl() {
      return this.imageForm?.image?.location;
    },
  },
  watch: {
    visible() {
      if (this.mediaUid) {
        this.getImage(this.mediaUid);
      } else {
        this.initForm();
      }
    },
  },
  async created() {
    if (this.isEditMode) {
      await this.getImage(this.mediaUid);
    } else {
      await this.initForm();
    }
  },
  methods: {
    async initForm() {
      this.resetForm();
    },
    async getImage(mediaUid) {
      try {
        this.resetForm();
        this.loading = true;

        const data = await getImage({ mediaUid });

        this.image = cloneDeep(data.image);
        this.imageForm = data.image;

        this.imageForm.tags = this.imageForm.tags || [];

        this.loading = false;
      } catch (e) {
        this.loading = false;

        this.onCancel();

        throw e;
      }
    },
    async onSubmit() {
        const updatedImage = this.imageForm;
        if (Object.keys(updatedImage).length > 0) {
          try {
            this.saving = true;

            await updateImage(updatedImage);

            this.$message({
              type: 'success',
              message: 'Data has been saved successfully',
            });

            await this.getImage(this.imageForm.mediaUid);
            this.saving = false;

            this.$emit('update');
          } catch (e) {
            this.saving = false;
          }
        }

        this.close();
    },
    resetForm() {
      this.image = {};
      this.imageForm = {
        mediaUid: null,
        isActive: true,
        tags: [],
      };

      this.$nextTick(() => {
        this.$refs.imageForm.resetFields();
        this.$refs.gamePageImage.reset();
      });
    },
    onCancel() {
      this.close();
    },
    close() {
      this.$emit('update:visible', false);
    },
    handleImageUploadSuccess(data) {
      this.imageForm.mediaUid = data.mediaUid;
    },
    handleTagClose(tag) {
      this.imageForm.tags.splice(this.imageForm.tags.indexOf(tag), 1);
    },
    showInput() {
      this.tagInputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleTagInputConfirm() {
      if (this.tagInputValue) {
        this.imageForm.tags.push(this.tagInputValue);
      }
      this.tagInputVisible = false;
      this.tagInputValue = '';
    },
  },
};
</script>
